<template>
  <div
    class="tooele-army-depot px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/Testing/2015-Partnership-Logo-min.png"
          width="460"
          height="235"
          alt=""
          title="2015-Partnership-Logo"
          class="img-responsive wp-image-12164"
        />
        <img
          src="@/assets/Testing/TEADArialImage-min.png"
          width="443"
          height="500"
          alt=""
          title="Test Site Arial Image"
          class="img-responsive wp-image-12165"
        />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Test Site at Tooele Army Depot" size="large" />
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS) has partnered with the
            <a href="http://www.tooele.army.mil/" target="_new"
              >Tooele Army Depot (TEAD)</a
            >
            to yield a very capable test site to perform large-scale and
            small-scale tests of energetic substances and articles. The TEAD/SMS
            partnership provides a unique set of capabilities for government and
            commercial clients. SMS has invested over a half million dollars in
            the test site. In-process and DOT/UN testing is completed at the
            site. Below are some of the features of the test site.
          </p>
          <ul>
            <li>94 acre dedicated test area</li>
            <li>
              24/7 secured, restricted-access location
              <ul>
                <li>clearances necessary to test CLASSIFIED materials</li>
              </ul>
            </li>
            <li>
              Sensitivity lab with Impact, Friction, ESD, and SBAT apparatuses
            </li>
            <li>400 lb (HD 1.1) detonation limit</li>
            <li>
              Extensive auxiliary equipment support including:
              <ul>
                <li>High-speed video</li>
                <li>Radiometry (heat-flux) measurement</li>
                <li>Reflective and side-on overpressure measurement</li>
                <li>High definition video</li>
                <li>Internal pressure measurement</li>
                <li>Detonation velocity measurement</li>
              </ul>
            </li>
            <li>Remote 12-meter (40 foot) drop tower</li>
            <li>High and low temperature conditioning chambers</li>
          </ul>
          <p>
            Our sensitivity test lab (pictured below) contains
            <router-link
              title="MBOB, BOE and Type-12 Impact"
              to="/test-equipment/mbom-type-12-and-boe-impact"
              >MBOM, BOE and Type-12 Impact</router-link
            >,
            <router-link title="ABL ESD" to="/test-equipment/abl-esd"
              >ABL ESD</router-link
            >, and
            <router-link title="ABL Friction" to="/test-equipment/abl-friction"
              >ABL Friction</router-link
            >
            (BAM Friction upon request) test machines.
            <router-link title="GoDetect" to="/test-equipment/godetect"
              >GoDetect<sup>TM</sup></router-link
            >
            is used to determine reactions with use of a high-speed camera (a
            Phantom V210). A gas analyzer is also available to determine
            reactions during sensitivity testing. The laboratory is equipped
            with humidity and temperature controls.
          </p>
          <p>
            The thermal lab is equipped with multiple pieces of equipment
            including conditioning ovens, an
            <router-link title="SBAT" to="/test-equipment/sbat"
              >SBAT</router-link
            >, <router-link title="Koenen" to="/koenen">Koenen</router-link>,
            and
            <router-link
              title="Time-Pressure"
              to="/test-equipment/time-pressure-apparatus"
              >Time-Pressure</router-link
            >
            devices. The below image shows the Thermal lab with the pieces of
            equipment labeled.
          </p>
          <p>
            There are significant testing capabilities at our TEAD/SMS test
            site. Please contact us with your testing requests and we will
            respond in a timely manner with competitive prices.
          </p>
        </Paragraph>
      </div>
    </div>
    <Paragraph></Paragraph>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Tooele Army Depot",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS has partnered with the Tooele Army Depot (TEAD). This partnership allows SMS to perform large-scale and small-scale tests and all DOT/UN testing."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.tooele-army-depot {
  a {
    color: #aa222c;
  }
}
</style>
